var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stewardManageForm"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"用户类别","prop":"authSpaceTypes","rules":[
        { required: true, validator: _vm.spaceTypeValid, trigger: 'change' },
      ]}},[_c('div',{staticClass:"check-panel"},_vm._l((_vm.userTypeOps),function(item,index){return _c('v-checkbox',{key:index,attrs:{"index":index,"type":"border","label":item.label},on:{"change":_vm.checkChange},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})}),1)]),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.authSpaceTypes !== undefined),expression:"form.authSpaceTypes !== undefined"}],attrs:{"label":"区域","prop":"authSpaceIds","rules":[{ required: false, message: '请选择区域', trigger: 'change' }]}},[_c('v-cascader',{attrs:{"placeholder":"默认表示全部","width":_vm.width,"options":_vm.sectionOps,"props":_vm.props},on:{"change":_vm.sectionChange},model:{value:(_vm.authSpaceIds),callback:function ($$v) {_vm.authSpaceIds=$$v},expression:"authSpaceIds"}})],1),_c('el-form-item',{attrs:{"label":"姓名","rules":[{ required: true, message: '请输入姓名', trigger: 'change' }],"prop":"name"}},[_c('v-input',{attrs:{"width":_vm.width,"placeholder":"请输入姓名"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"手机号","rules":[
        { required: true, message: '请输入手机号', trigger: 'change' },
        { validator: _vm.validatePhone, trigger: 'change' },
      ],"prop":"mobile"}},[_c('v-input',{attrs:{"width":_vm.width,"placeholder":"请输入手机号"},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}})],1),_c('el-form-item',{attrs:{"label":"图片","rules":[
        {
          required: true,
          message: '请上传图片',
          trigger: ['change', 'blur'],
        },
      ],"prop":"picture"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.form.picture},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "picture", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "picture", $event)}}})],1),_c('el-form-item',{attrs:{"label":"微信二维码","rules":[
        {
          required: true,
          message: '请上传微信二维码',
          trigger: ['change', 'blur'],
        },
      ],"prop":"wechatQrcode"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.form.wechatQrcode},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "wechatQrcode", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "wechatQrcode", $event)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }