<template>
  <div class="stewardManageForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="用户类别"
        prop="authSpaceTypes"
        :rules="[
          { required: true, validator: spaceTypeValid, trigger: 'change' },
        ]"
      >
        <div class="check-panel">
          <v-checkbox
            v-model="item.checked"
            v-for="(item, index) in userTypeOps"
            :key="index"
            :index="index"
            type="border"
            :label="item.label"
            @change="checkChange"
          />
        </div>
      </el-form-item>

      <el-form-item
        v-show="form.authSpaceTypes !== undefined"
        label="区域"
        prop="authSpaceIds"
        :rules="[{ required: false, message: '请选择区域', trigger: 'change' }]"
      >
        <v-cascader
          placeholder="默认表示全部"
          :width="width"
          v-model="authSpaceIds"
          :options="sectionOps"
          :props="props"
          @change="sectionChange"
        />
      </el-form-item>
      <el-form-item
        label="姓名"
        :rules="[{ required: true, message: '请输入姓名', trigger: 'change' }]"
        prop="name"
      >
        <v-input
          :width="width"
          placeholder="请输入姓名"
          v-model="form.name"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="手机号"
        :rules="[
          { required: true, message: '请输入手机号', trigger: 'change' },
          { validator: validatePhone, trigger: 'change' },
        ]"
        prop="mobile"
      >
        <v-input
          :width="width"
          placeholder="请输入手机号"
          v-model="form.mobile"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="图片"
        :rules="[
          {
            required: true,
            message: '请上传图片',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="picture"
      >
        <v-upload :limit="1" :imgUrls.sync="form.picture" />
      </el-form-item>
      <el-form-item
        label="微信二维码"
        :rules="[
          {
            required: true,
            message: '请上传微信二维码',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="wechatQrcode"
      >
        <v-upload :limit="1" :imgUrls.sync="form.wechatQrcode" />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { mobileRegular } from "@/utils/regular";
import { addButlerUrl, butlerDetailUrl, getBuildListURL } from "./api.js";
import { setUserTypeOps } from "./map.js";
export default {
  name: "partyMemberForm",
  data() {
    return {
      width: 250,
      submitConfig: {
        queryUrl: butlerDetailUrl,
        submitUrl: addButlerUrl,
      },
      props: {
        multiple: true,
        label: "name",
        value: "id",
        children: "children",
      },
      userTypeOps: setUserTypeOps(),
      sectionOps: [], // 用于显示的数据
      spacePathIds: [], // 添加treeIds的数据
      authSpaceIds: [],
      residentsOps: [], // 用户
      form: {
        id: "",
        picture: "",
        wechatQrcode: "",
        mobile: "",
        name: "",
        authSpaceIds: "",
        authSpaceTypes: undefined, // 用户类别id
      },
      merchantsOps: [], // 商圈
      allOps: [], // 用户+商圈
    };
  },
  computed: {
    authSpaceTypes() {
      return this.form.authSpaceTypes;
    },
  },
  watch: {
    userTypeOps: {
      handler(val) {
        const checkOps = val.filter((v) => v.checked);
        this.form.authSpaceTypes =
          checkOps.length > 0
            ? checkOps.map((v) => v.value).join(",")
            : undefined;
      },
      deep: true,
    },
    authSpaceTypes() {
      let types =
        this.form.authSpaceTypes !== undefined
          ? this.form.authSpaceTypes.split(",")
          : [];
      let result = [];
      if (types.includes("5") && types.includes("4")) {
        result = [...this.allOps];
      } else if (types.includes("5")) {
        result.push(...this.merchantsOps);
      } else if (types.includes("4")) {
        result.push(...this.residentsOps);
      }
      this.sectionOps = result;
      console.log(this.sectionOps);
    },
    // 选中的区域集合
    authSpaceIds: {
      handler(val) {
        this.form.authSpaceIds = val.map((a) => a[a.length - 1]).join(",");
      },
      deep: true,
    },
  },
  components: {},
  async mounted() {
    const { id } = this.$route.query;
    this.form.id = id;
    // 4 居民 5 商户
    await Promise.all([
      this.getBuildList(4),
      this.getBuildList(5),
      this.getBuildList("4,5"),
    ]);
    if (id !== undefined) {
      // this.$refs.formPanel.getData({ id: id });
      // this.$nextTick(() => {
      this.$refs.formPanel.getData({ id: id });
      // });
    }

    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    // 数据添加 parentIds
    dealDataAddParentIds(data, ids = []) {
      data.forEach((v) => {
        if (v.children) {
          this.dealDataAddParentIds(v.children, [...ids, v.id]);
        } else {
          this.spacePathIds.push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      return data;
    },
    async getBuildList(type) {
      let res = await this.$axios.get(
        `${getBuildListURL}?specifyCollectionTypes=${type}`
      );
      if (res.code === 200) {
        // type = 4 居民 5 商户
        let opt = this.dealDataAddParentIds(res.data);
        if (type === 4) {
          this.residentsOps = opt;
        } else if (type === 5) {
          this.merchantsOps = opt;
        } else {
          this.allOps = opt;
        }
      }
    },
    checkChange() {
      // 每次改变时, 清空默认选择项
      this.authSpaceIds = [];
    },
    sectionChange() {},
    spaceTypeValid(rule, value, callback) {
      if (value === undefined) {
        callback(new Error("请选择用户类别"));
      } else {
        callback();
      }
    },
    change() {},
    validatePhone(rule, value, callback) {
      let regExp = mobileRegular;
      if (!regExp.test(value)) {
        callback(new Error("您填写手机号码格式不正确"));
      } else {
        callback();
      }
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        if (key === "authSpaceTypes" && !data[key]) {
          this.form[key] = "";
          return;
        }
        this.form[key] = data[key];
      });
      const ids = this.form.authSpaceIds
        ? this.form.authSpaceIds.split(",")
        : [];
      this.spacePathIds.forEach((item) => {
        console.log(item, "++++++++++++++");
        if (ids.includes(item.id)) {
          this.authSpaceIds.push([...item.treeIds]);
        }
      });
      let types = this.form.authSpaceTypes.split(",");
      this.userTypeOps.forEach((v) => {
        if (types.includes(v.value)) {
          v.checked = true;
        }
      });
    },
    submitBefore() {
      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.stewardManageForm {
  box-sizing: border-box;
  height: 100%;
  .check-panel {
    display: flex;
  }
}
</style>
<style lang="less"></style>
